export default function modelComposicao(
    id = -1,
    cod_produto = -1,
    produto = null,
    quantidade = 1,
    custo = 0,
    venda = 0,
    produto_id = null
) {
    this.id = id,
    this.cod_produto = cod_produto,
    this.produto = produto,
    this.quantidade = quantidade,
    this.custo = custo,
    this.venda = venda,
    this.produto_id = produto_id;
}