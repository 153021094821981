export default function modelIntegracaoFornecedor(
    id = -1,
    cod_produto = -1,
    fornecedor_id = -1,
    fornecedor = null,
    codigo = null,
    descricao = null,
    fator = 1
) {
    this.id = id;
    this.cod_produto = cod_produto,
    this.fornecedor_id = fornecedor_id;
    this.fornecedor = fornecedor;
    this.codigo = codigo;
    this.descricao = descricao;
    this.fator = fator;
}