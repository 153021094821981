<script>
import appConfig from "@/app.config";
import { http } from "../../../helpers/easyindustriaapi/config";
import {
  authEasyMethods,
  notificationMethods
} from "@/state/helpers";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { modelProduto } from '@/model/produto.js';
import List from "./list";
import Insert from "./insert";
import Edit from "./edit";

export default {
  page: {
    title: "Produtos",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, List, Insert, Edit },
  data() {
    return {
      vet_uf_icms:[],
      text: 'Produtos',
      loader: {
        get: false
      },
      currentUser: null,
      currentEmpresa: null,
      submitted: false,
      produtos: [],
      currentProduto: null,
      titleBody: "Listagem dos Produtos",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Produtos",
          href: "/produtos",
          active: true,
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { label: "ID", key: "id", sortable: true, tdClass: 'text-right', thClass: 'text-center' },
        { label: "Nome", key: "produto_nome", sortable: true, thClass: 'text-center' },
        { label: "Referência", key: "codbarras", sortable: true, tdClass: 'text-right', thClass: 'text-center' },
        { label: "UN", key: "um", sortable: false, thClass: 'text-center' },
        { label: 'Preço', key: 'preco', sortable: false, tdClass: 'text-right', thClass: 'text-center' },
        { label: "Ações", key: "acoes", sortable: false, tdClass: 'text-center', thClass: 'text-center' }
      ],
      editando: false,
      inserindo: false,
      tabelasOpcoes: undefined,
      tabelasImpostos: undefined,
    }
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));
    this.notification.clear;
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.produtos.length;
    },
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    clonefoot() {
      return (this.produtos.length > 10) ? true : false;
    },
    isHide() {
      return (!this.inserindo && !this.editando);
    },
    getAtivo() {
      return (this.currentProduto.ativo) ? 'Ativo' : 'Inativo';
    },
    getTipo() {
      if (this.tabelasOpcoes == undefined) {
        return null;
      }

      var tipoProduto = this.tabelasOpcoes.tiposProduto.filter(valor => {
        return valor.id === this.currentProduto.idtipo;
      });

      if (tipoProduto.length === 0) {
        return null;
      }

      return tipoProduto[0].descricao;
    },
    getGrupo() {
      if (this.tabelasOpcoes == undefined) {
        return null;
      }

      var grupoProduto = this.tabelasOpcoes.grupos.filter(valor => {
        return valor.id === this.currentProduto.grupo_id;
      });

      if (grupoProduto.length === 0) {
        return null;
      }

      return grupoProduto[0].descricao;
    },
  },
  mounted() {
    this.getData();
    this.getImpostos();
    this.currentProduto = modelProduto;
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getDadosGerais();
    this.tabela_icms_ufs();
  },
  methods: {
    ...authEasyMethods,
    ...notificationMethods,
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none") {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block") {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 1000);
      }
    },
    makeToast(variant = null, message = 'Error generic') {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        appendToast: true
      });
    },
   async  tabela_icms_ufs(){
      this.onLoader();
      this.loader.get = true;
      try {
        await http.get('/uf-icms?empresa_id=' + this.currentEmpresa.id)
          .then(res => {
            this.vet_uf_icms = res.data ? res.data : null;
            // console.log("Mostrando a Tabela de Icms de ufs")
            // console.log(this.vet_uf_icms)
          })
          .finally(() => {
            // 
            setTimeout(() => this.offLoader(), 1);
            this.loader.get = false;
          });
      } catch (error) {
        this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error('Usuário não tem permissão!');
        }
      }
    },
    async edit(produto) {
      //this.onLoader();
      await this.getDadosGerais();
      await this.getImpostos();
      this.currentProduto = produto;
      this.changeValues(produto, ".", ",");
      this.editando = !this.editando;
      this.offLoader();
    },
    async append() {
      this.onLoader();
      await this.getDadosGerais();
      await this.getImpostos();
      this.currentProduto = new modelProduto(-1);
      this.inserindo = !this.inserindo;
      this.offLoader();
    },

    async doPost(produto) {
      var numsStr = produto.produto_margemlucro.replace(/[^0-9]/g, '');
      produto.produto_margemlucro = parseInt(numsStr);
      this.changeValues(produto, ",", ".");
      console.log("mostrando produto no post Crrentt")
      console.log(this.currentProduto)
      this.onLoader();
      try {
        let response = await http.post('/produto?empresa_id=' + this.currentEmpresa.id, this.currentProduto)
          .catch((error) => {
            this.error(error.response);
            this.makeToast('danger', error.response);
          });

        if (response.status === 200) {
          this.getData();
          this.back();
          this.offLoader();
          this.makeToast('success', 'Registro incluído');
        }
      } catch (error) {
        this.offLoader();
        this.makeToast('danger', error.response);
      }
    },
    async doPut(produto) {
      console.log(produto);
      this.onLoader();
      this.changeValues(produto, ",", ".");

      console.log("dentro do update");
    

      try {

        let response = await http.put('/produto/' + this.currentProduto.id + '?empresa_id=' + this.currentEmpresa.id, produto);
        //console.log(response);

        if (response.status === 200) {
          console.log("entrou aqui");
          this.getData();
          //this.back();
          setTimeout(() => {
            this.offLoader();
          }, 1000);
          this.makeToast('success', 'Registro alterado');
        }
      } catch (error) {
        this.makeToast('danger', error.response);
      }
    },
    async doDelete(produto) {
      this.onLoader();
      try {
        let response = await http.delete('/produto/' + produto.id + '?empresa_id=' + this.currentEmpresa.id, produto)
          .catch((error) => {
            this.offLoader();
            //(error.response);
            this.makeToast('danger', error.response.data.error);
          });

        if (response.status && response.status === 200) {
          this.getData();
          this.offLoader();
          this.makeToast('warning', 'Registro excluído');
        }
      } catch (error) {
        this.offLoader();
        //  console.log(error);
      }
    },
    back() {
      this.inserindo = false;
      this.editando = false;
      this.titleBody = 'Listagem de Produtos'
    },
    async getData() {
     // console.clear();
      this.onLoader();
      this.loader.get = true;
      try {
        await http.get('/produto?empresa_id=' + this.currentEmpresa.id)
          .then(res => {
            this.produtos = res.data ? res.data : null;
            console.log(this.produtos)
          })
          .finally(() => {
            // 
            setTimeout(() => this.offLoader(), 1);
            this.loader.get = false;
          });
      } catch (error) {
        this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error('Usuário não tem permissão!');
        }
      }
    },
    async getImpostos() {
      try {
        if (this.tabelasImpostos == undefined) {
          await http.get('/tabelas/impostos')
            .then(res => {
              this.tabelasImpostos = res.data;
              //console.log(this.tabelasImpostos)
            })
            .finally(() => {
            });
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          this.error('Usuário não tem permissão!');
        }
      }
    },
    async getDadosGerais() {
      console.log("entrou em dados Gerais")
      try {
        if (this.tabelasOpcoes == undefined) {
          await http.get('/tabelas/dadosgerais?empresa_id=' + this.currentEmpresa.id)
            .then(res => {
             // console.log(this.currentEmpresa.id)
              this.tabelasOpcoes = res.data;
              console.log("mostrando Dados Gerais")
              console.log(this.tabelasOpcoes)
            })
            .finally(() => {
            });
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          this.error('Usuário não tem permissão!');
        }
      }
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    changeValues(produto, oldStr, newStr) {
      var preco;

      if (!isNaN(parseFloat(produto.preco))) {
        preco = produto.preco.toString().replace(oldStr, newStr);
      } else {
        preco = produto.preco.replace(oldStr, newStr);
      }

      this.currentProduto.preco = preco;
      var atacado = produto.preco_atacado.toString().replace(oldStr, newStr);
      this.currentProduto.preco_atacado = atacado;
      var custo = produto.preco_custo.toString().replace(oldStr, newStr);
      this.currentProduto.preco_custo = custo;
      var fatexport = produto.produto_fatexport.toString().replace(oldStr, newStr);
      this.currentProduto.produto_fatexport = fatexport;
      var margemlucro = produto.produto_margemlucro.toString().replace(oldStr, newStr);
      this.currentProduto.produto_margemlucro = margemlucro;
      var qtd = (produto.qtd) ? produto.qtd.toString().replace(oldStr, newStr) : '1,00';
      this.currentProduto.qtd = qtd;
      var pesoliq = produto.produto_pesoliq.toString().replace(oldStr, newStr);
      this.currentProduto.produto_pesoliq = pesoliq;
      var pesobruto = produto.produto_pesobruto.toString().replace(oldStr, newStr);
      this.currentProduto.produto_pesobruto = pesobruto;
    },
    setProduto(item) {
      this.currentProduto = item;
    },
    async setProdutoDetail(produto) {
      this.onLoader();
      await this.getDadosGerais();
      await this.getImpostos();
      this.currentProduto = produto;
      this.offLoader();
    },
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-alert :variant="notification.type" class="mt-3" v-if="notification.message" show
              dismissible>{{ notification.message }}</b-alert>
            <div class="row">
              <div class="col-sm-12 col-md-6">
              </div>
              <div class="col-sm-12 col-md-6 text-md-right">
                <button v-if="isHide" class="btn btn-success" @click="append()">+ Incluir Produto</button>
                <button v-if="!isHide" class="btn btn-secondary" @click="back()">Voltar</button>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <Edit v-if="editando" @back="back" @doPut="doPut" @newTitle="changeHomeTitle" :oldProduto="currentProduto"
              :currentEmpresa="currentEmpresa" :listTabelas="tabelasOpcoes" :listImpostos="tabelasImpostos" :vet_uf_icms="vet_uf_icms" />
            <Insert v-if="inserindo" @back="back" @doPost="doPost" @newTitle="changeHomeTitle" :listProdutos="produtos"
              :newProduto="currentProduto" :currentEmpresa="currentEmpresa" :listTabelas="tabelasOpcoes"
              :listImpostos="tabelasImpostos" :vet_uf_icms="vet_uf_icms" />
          </div>
          <List v-if="isHide" :currentEmpresa="currentEmpresa" :hide="isHide" :listProdutos="produtos" @edit="edit"
            @doDelete="doDelete" />
        </div>
      </div>
    </div>
  </Layout>
</template>