<script>
import Detail from "./detail";
import Vue from "vue";
import VueDirectiveTooltip from "vue-directive-tooltip";
Vue.use(VueDirectiveTooltip, {
  delay: 0,
});

export default {
  props: {
    currentEmpresa: { type: Object, required: true },
    listProdutos: { type: Array, required: true },
    hide: { type: Boolean, required: true },
  },
  components: { Detail },
  data() {
    return {
      titleBody: "Listagem dos Produtos",
      currentProduto: undefined,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        {
          label: "ID",
          key: "id",
          sortable: true,
          tdClass: "text-left",
          thClass: "text-left",
        },
        {
          label: "Nome",
          key: "produto_nome",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Referência",
          key: "codbarras",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "UN",
          key: "um",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Preço",
          key: "preco",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
          formatter: "formatCurrency",
        },
        {
          label: "Ações",
          key: "acoes",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.listProdutos.length ? this.listProdutos.length : 0;
    },
    clonefoot() {
      return this.listProdutos.length || this.listProdutos.length > 10
        ? true
        : false;
    },
  },
  mounted() {
    this.$emit("newTitle", this.titleBody);
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // eslint-disable-next-line no-unused-vars
    formatCurrency(value, key, item) {
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    setProduto(value) {
      this.currentProduto = value;
      this.$refs["modal-produto"].show();
    },
    setEdit(value) {
      ///this.setProduto(value);
      this.$emit("edit", value);
    },
    setDelete(value) {
      this.$emit("doDelete", value);
    },
  },
};
</script>

<template>
  <div class="card-body" v-if="hide">
    <div class="row mt-4">
      <!-- Start Limit -->
      <div class="col-sm-12 col-md-6">
        <div id="produto-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Exibir&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;registros
          </label>
        </div>
      </div>
      <!-- End Limit -->
      <!-- Start Search -->
      <div class="col-sm-12 col-md-6">
        <div id="produto-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            Pesquisar:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Pesquisar..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <!-- Start Table -->
    <div class="row mt-4">
      <div v-if="hide" class="table-responsive mb-0">
        <b-table
          :items="listProdutos"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          :hover="true"
          :foot-clone="clonefoot"
        >
          <template #cell(acoes)="row">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <b-dropdown v-bind:id="'dropdown-' + row.item.id" class="m-md-2">
              <template #button-content>
                <i data-v-6289eca4="" class="bx ri-menu-line"></i>
              </template>
              <b-dropdown-item
                v-b-modal.modal-produto
                @click="setProduto(row.item)"
                ><i class="bx ri-eraser-fill"></i> Visualizar</b-dropdown-item
              >
              <b-dropdown-item @click="setEdit(row.item)"
                ><i class="bx ri-edit-2-line"></i> Alterar</b-dropdown-item
              >
              <b-dropdown-item @click="setDelete(row.item)"
                ><i class="bx ri-eraser-fill"></i> Excluir</b-dropdown-item
              >
            </b-dropdown>
          </template>
          <template #cell(preco)="row">
            <div>
              <span>
                <b-badge
                  data-name="dinheiro"
                  class="field-status"
                  variant="success"
                  v-tooltip.bottom="'Preço do Produto'"
                >{{'R$'}}{{ formatterCurrBR(row.item.preco) }}</b-badge>
              </span>
              
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <!-- End Table -->
    <!-- Start Pagination -->
    <div v-if="hide" class="row mt-4">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    <!-- End Pagination -->
    <!-- Start Modal Produto -->
    <b-modal
      v-if="hide"
      hide-footer
      id="modal-produto"
      ref="modal-produto"
      :title="'Detalhes do Produto'"
      title-class="font-18"
    >
      <Detail :produto="currentProduto" />
    </b-modal>
    <!-- End Modal Produto -->
  </div>
</template>