export const listOrigens = [
  { name: "0 - Nacional, exceto as indicadas nos códigos 3, 4, 5 E 8", id: 0, },
  { name: "1 - Estrangeira - Importação Direta, exceto a indicada no código 6", id: 1 },
  { name: "2 - Estrangeira - Adquirida no Mercado Interno, exceto a indicada no código 7", id: 2 },
  { name: "3 - Nacional, mercadoria ou bem com conteúdo de Importação superior a 40% e inferior ou igual a 70%", id:3 },
  { name: "4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legisla'#231#245'es citadas nos Ajustes", id: 4 },
  { name: "5 - Nacional, mercadoria ou bem com conteúdo de Importação inferior ou igual a 40%", id: 5 },
  { name: "6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natural", id: 6 },
  { name: "7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista da CAMEX e gás natural", id: 7 },
];
