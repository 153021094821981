export function modelProduto (
    id = null,
    codbarras = null,
    produto_nome = null,
    produto_descricao = null,
    idtipo = 1,
    produto_m_s = null,
    produto_ncm = null,
    produto_cest = null,
    preco_custo = 0,
    preco = 0,
    produto_margemlucro = 0,
    preco_atacado = null,
    unidade_id = null,
    um = null,
    qtd = 0,
    produto_finalidade = null,
    ativo = 1,
    grupo_id = null,
    produto_controla_estoque = 0,
    produto_balanca = 0,
    oc_name = null,
    oc_id = null,
    oc_tag = 0,
    opencart = 0,
    oc_image = null,
    produto_picms = 0,
    produto_produzido = 0,
    produto_cean = null,
    produto_orig = null,
    oc_description = null,
    oc_modelo = null,
    produto_cst = null,
    produto_cstpis = null,
    produto_cstcofins = null,
    produto_cstipi = null,
    produto_pbc_icms = null,
    produto_cfop = null,
    produto_picmsst = null,
    produto_pbc_icmsst = null,
    produto_pbc_ipi = null,
    produto_pipi = null,
    produto_pbc_pis = null,
    produto_ppis = null,
    produto_pbc_cofins = null,
    produto_pcofins = null,
    produto_ppisst = null,
    produto_pBC_pisst = null,
    produto_pbc_cofinsst = null,
    produto_pcofinsst = null,
    produto_cste = null,
    produto_picmse = 0,
    produto_pbc_icmse = null,
    produto_picmsste = null,
    produto_pbc_icmsste = null,
    produto_cenqe = null,
    produto_pipie = null,
    produto_pbc_ipie = null,
    produto_ppise = null,
    produto_pbc_pise = null,
    produto_ppisste = null,
    produto_pBC_pisste = null,
    produto_pcofinse = null,
    produto_pbc_cofinse = null,
    produto_pcofinsste = null,
    produto_pbc_cofinsste = null,
    produto_materiaprima = 0,
    produto_class_sped = null,
    produto_pesoliq = 0,
    produto_pesobruto = 0,
    produto_fatexport = 1,
    produto_estminimo = 1,
    produto_cod_int = null,
    produto_cta = null,
    produto_intdominio = null,
    produto_nTipoItem = 0,
    composicoes,
    integracaoFornecedores = [],
    tabelaICMS = [],
    tabelaPIS = [],
    tableCOFINS = [],
    tabelaPrecos = [],
    produto_cod=null
) {
    this.id = id;
    this.codbarras = codbarras;
    this.produto_nome = produto_nome;
    this.produto_descricao = produto_descricao;
    this.idtipo = idtipo;
    this.produto_m_s = produto_m_s;
    this.produto_ncm = produto_ncm;
    this.produto_cest = produto_cest;
    this.preco_custo = preco_custo;
    this.preco = preco;
    this.produto_margemlucro = produto_margemlucro;
    this.preco_atacado = preco_atacado;
    this.unidade_id = unidade_id;
    this.um = um;
    this.qtd = qtd;
    this.produto_finalidade = produto_finalidade;
    this.ativo = ativo;
    this.grupo_id = grupo_id;
    this.produto_controla_estoque = produto_controla_estoque;
    this.produto_balanca = produto_balanca;
    this.oc_name = oc_name;
    this.oc_id = oc_id;
    this.oc_tag = oc_tag;
    this.opencart = opencart;
    this.oc_image = oc_image;
    this.produto_cste = produto_cste;
    this.produto_picms = produto_picms;
    this.produto_produzido = produto_produzido;
    this.produto_cean = produto_cean;
    this.produto_orig = produto_orig;
    this.oc_description = oc_description;
    this.oc_modelo = oc_modelo;
    this.produto_cst = produto_cst;
    this.produto_cstpis = produto_cstpis;
    this.produto_cstcofins = produto_cstcofins;
    this.produto_cstipi = produto_cstipi;
    this.produto_pbc_icms = produto_pbc_icms;
    this.produto_cfop = produto_cfop;
    this.produto_picmsst = produto_picmsst;
    this.produto_pbc_icmsst = produto_pbc_icmsst;
    this.produto_pbc_ipi = produto_pbc_ipi;
    this.produto_pipi = produto_pipi;
    this.produto_pbc_pis = produto_pbc_pis;
    this.produto_ppis = produto_ppis;
    this.produto_pbc_cofins = produto_pbc_cofins;
    this.produto_pcofins = produto_pcofins;
    this.produto_ppisst = produto_ppisst;
    this.produto_pBC_pisst = produto_pBC_pisst;
    this.produto_pbc_cofinsst = produto_pbc_cofinsst;
    this.produto_pcofinsst = produto_pcofinsst;
    this.produto_picmse = produto_picmse;
    this.produto_pbc_icmse = produto_pbc_icmse;
    this.produto_picmsste = produto_picmsste;
    this.produto_pbc_icmsste = produto_pbc_icmsste;
    this.produto_cenqe = produto_cenqe;
    this.produto_pipie = produto_pipie;
    this.produto_pbc_ipie = produto_pbc_ipie;
    this.produto_ppise = produto_ppise;
    this.produto_pbc_pise = produto_pbc_pise;
    this.produto_ppisste = produto_ppisste;
    this.produto_pBC_pisste = produto_pBC_pisste;
    this.produto_pcofinse = produto_pcofinse;
    this.produto_pbc_cofinse = produto_pbc_cofinse;
    this.produto_pcofinsste = produto_pcofinsste;
    this.produto_pbc_cofinsste = produto_pbc_cofinsste;
    this.produto_materiaprima = produto_materiaprima;
    this.produto_class_sped = produto_class_sped;
    this.produto_pesoliq = produto_pesoliq;
    this.produto_pesobruto = produto_pesobruto;
    this.produto_fatexport = produto_fatexport;
    this.produto_estminimo = produto_estminimo;
    this.produto_cod_int = produto_cod_int;
    this.produto_cta = produto_cta;
    this.produto_intdominio = produto_intdominio;
    this.produto_nTipoItem = produto_nTipoItem;
    this.composicoes = composicoes;
    this.integracaoFornecedores = integracaoFornecedores;
    this.tabelaICMS = tabelaICMS;
    this.tabelaPIS = tabelaPIS;
    this.tableCOFINS = tableCOFINS;
    this.tabelaPrecos = tabelaPrecos;
    this.produto_cod = produto_cod;
}